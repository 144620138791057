@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SofiaPro";
  src: url("assets/fonts/sofia-pro/sofia-pro-regular.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "SofiaPro";
  src: url("assets/fonts/sofia-pro/sofia-pro-medium.otf");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "SofiaPro";
  src: url("assets/fonts/sofia-pro/sofia-pro-bold.otf");
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

h1, h2, h3, h4, h5, h6, ul, ul li, p, a, input, input::placeholder, label, button, input, select {
    font-family: "SofiaPro", sans-serif;
}

a {
  color: #05194C;
}

h1, h2 {
  font-weight: 500;
}

div.ql-toolbar.ql-snow {
  border: none;
  text-align: right;
  margin-bottom: 10px;
  font-family: 'SofiaPro', sans-serif;
}

.ql-snow .ql-picker-label {
  text-align: left;
}

.text-editor {
  width: 100%;
  margin: 0 auto;
  /* box-shadow: 5px 5px 1px #000; */
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  min-height: 100px;
  border-radius: 5px;
  /* box-shadow: 0PX 0PX 5PX #00000033; */
  border: 1px solid #bcbcbc;
  padding: 25px !important;
}

.validation-error .ql-editor{
  border: 1px solid #F26C6C;
}

.validation-error label {
  color: #F26C6C;
}

.long-text .ql-editor {
  min-height: 300px;
  overflow-y: scroll;
}

.borderless .ql-editor {
  border:none;
  overflow:hidden;
  height: inherit;
}

select.default {
  -webkit-appearance: auto;
  appearance: auto;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: url('./assets/images/icons/chevron-down.svg');
  position: absolute;
  background-size: 12px 12px;
  height: 12px;
  width: 12px;
  /* font-size: 1rem; */
  top: 10px;
  right: 12px;
}

input[type="date"].stepper::-webkit-inner-spin-button,
input[type="date"].stepper::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.date-picker-wrapper input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: inherit;
}

input[type="date"].custom::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}


/* File Drop */
.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 200px;
  width: 100%;
  border: 1px dashed #4B5563;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor:pointer
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: #2664eb1d;
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #ff6e40;
  /* box-shadow: 0 0 13px 3px #0000000a; */
  border: 1px solid #2664ebde;
}

.print-class {
  /* padding: 20px; */
  margin: 10mm 10mm 10mm 10mm;
}

.print-class .print-white-bg {
  background-color: white !important;
}

.print-class .print-flex-row {
  display: flex;
  flex-direction: row;
}

.print-class .print-underline {
  border-bottom: 1px solid #05194C;
  border-radius:0 !important
}

.print-class .print-block {
  display: inline-block !important;
}

@page  
{ 
    size: auto;   /* auto is the initial value */ 

    /* this affects the margin in the printer settings */ 
    margin: 10mm 10mm 10mm 10mm;
} 

/* Animated Dots */
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #999;
  color: #999;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -8px;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #999;
  color: #999;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #999;
  color: #999;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: rgb(116, 116, 116);
  }
  50%,
  100% {
    background-color: #c6c6c6;
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}